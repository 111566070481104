<template>
	<div class="relative">
		<div>
			<!-- Trigger Button -->
			<button
				id="user-menu"
				class="focus:outline-hidden flex max-w-xs items-center rounded-full text-sm focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50"
				type="button"
				:aria-expanded="isOpened"
				aria-haspopup="true"
				@click="isOpened = !isOpened"
			>
				<MaybeImage :src="`https://unavatar.io/${user.eMailAddress}?fallback=false`">
					<template #loaded>
						<img class="size-8 rounded-full" :src="`https://unavatar.io/${user.eMailAddress}?fallback=false`" alt="" />
					</template>
					<template #notFound>
						<span class="inline-block size-8 overflow-hidden rounded-full bg-white">
							<svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
								<path
									d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
								/>
							</svg>
						</span>
					</template>
				</MaybeImage>

				<span class="relative ml-4 hidden text-sm font-medium text-gray-700 lg:block">
					<span class="sr-only">Open user menu for</span>
					{{ name }}
				</span>

				<IconCaretDown class="ml-4 hidden h-2 w-2 shrink-0 lg:block" />
			</button>
		</div>

		<div ref="target">
			<Transition
				enter-active-class="transition duration-100 ease-out"
				enter-from-class="transform scale-95 opacity-0"
				enter-to-class="transform scale-100 opacity-100"
				leave-active-class="transition duration-75 ease-in"
				leave-from-class="transform scale-100 opacity-100"
				leave-to-class="transform scale-95 opacity-0"
			>
				<div
					v-if="isOpened"
					class="focus:outline-hidden absolute right-0 z-30 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5"
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="user-menu"
				>
					<div class="px-4 py-3">
						<p v-if="!isImpersonating" class="text-sm">{{ $t('user-action.signed-as') }}</p>
						<p v-else class="text-sm">Impersonating user</p>
						<p class="mt-2 flex truncate text-sm font-medium text-gray-900">
							<Flag class="mr-1 inline" :code="userCountry" />
							<span>{{ user.username }}</span>
						</p>
					</div>

					<div v-if="user.type === UserType.Supervisor" class="px-4 py-3">
						<label class="text-sm" for="select-network">{{ $t('user-action.selected-network') }}</label>
						<select
							id="select-network"
							class="inline w-full cursor-pointer rounded border-none text-sm font-medium focus:border-blue-500 focus:ring-blue-500"
							name="select-network"
							:value="HttpClient.getEnvironment()"
							@change="changeNetwork($event.target.value)"
						>
							<option value="production">Production</option>
							<option value="uat">UAT</option>
							<option value="development">Development</option>
						</select>
					</div>
					<div class="pt-1" role="none">
						<RouterLink
							v-if="userProfileUrl"
							:to="userProfileUrl"
							class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
							role="menuitem"
						>
							{{ $t('user-action.my-profile') }}
						</RouterLink>
						<RouterLink
							to="/users/me"
							class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
							role="menuitem"
						>
							{{ $t('user-action.my-account') }}
						</RouterLink>
						<button
							v-if="isImpersonating"
							class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
							role="menuitem"
							@click="stopImpersonating"
						>
							Stop Impersonating
						</button>
						<button
							v-else
							class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
							role="menuitem"
							@click="logout"
						>
							{{ $t('user-action.sign-out') }}
						</button>
					</div>
				</div>
			</Transition>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { onClickOutside } from '@vueuse/core';
	import IconCaretDown from '~icons/fivb/carret-down';
	import HttpClient from '@/kernel/httpClient';
	import { UserType } from '@/sdk';
	import { convertCode } from '@/utils/convertCode';
	import { useAuth } from '~/app/auth/composables/use-auth';
	import { useIsImpersonating } from '~/app/auth/composables/use-is-impersonating';
	import { useLogout } from '~/app/auth/queries/use-logout';
	import { useStopImpersonating } from '~/app/auth/queries/use-stop-impersonating';
	import MaybeImage from '~/app/common/components/maybe-image.vue';
	import Flag from '~/design-system/flag.vue';
	import { changeEnvironment } from '~/infrastructure/http';

	const isOpened = ref(false);
	const user = useAuth();
	const isImpersonating = useIsImpersonating();

	const target = ref(null);
	onClickOutside(target, () => (isOpened.value = false));

	const userProfileUrl = computed(() => {
		switch (user.type) {
			case UserType.Player:
				return `/players/${user.typeNo}`;
			case UserType.VolleyClub:
				return `/volley/clubs/${user.typeNo}`;
			case UserType.Federation:
				return `/federations/${user.typeNo}`;
			case UserType.Official:
				return `/officials/${user.typeNo}`;
			case UserType.Referee:
				return `/referees/${user.typeNo}`;
			case UserType.MediaPerson:
				return `/media-staff/${user.typeNo}`;
			case UserType.MedicalPerson:
				return `/medical-staff/${user.typeNo}`;
			case UserType.Coach:
				return `/coaches/${user.typeNo}`;
		}

		return false;
	});

	const userCountry = computed(() => {
		if (user.information.countryCode?.length === 3) {
			return user.information.countryCode;
		}

		if (!user.information.countryCode) {
			return '';
		}

		return convertCode('iso2', 'fivb', user.information.countryCode);
	});

	const name = computed(() => {
		if (user.firstName && user.lastName) {
			return `${user.firstName} ${user.lastName}`;
		}

		if (user.firstName && !user.lastName) {
			return `${user.firstName}`;
		}

		if (!user.firstName && user.lastName) {
			return `${user.lastName}`;
		}

		return user.username;
	});

	function changeNetwork(network) {
		HttpClient.changeEnvironment(network);
		changeEnvironment(network);

		// Force refresh to ensure that all data are coming from the correct endpoint
		window.location.reload();
	}

	const { mutate: sendStopImpersonating } = useStopImpersonating();
	function stopImpersonating() {
		sendStopImpersonating(undefined, {
			onSuccess() {
				// Force refresh to get a clean state
				window.location.href = '/';
			},
		});
	}

	const { mutate: sendLogout } = useLogout();
	function logout() {
		sendLogout(undefined, {
			onSuccess() {
				// Force refresh to get a clean state
				window.location.href = '/login';
			},
		});
	}
</script>
