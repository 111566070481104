<script lang="ts">
	import { useLayout } from '../composables/use-layout';

	const DynamicLayout = defineComponent({
		name: 'DynamicLayout',

		props: {
			title: {
				type: String,
				required: true,
			},

			fullscreen: {
				type: Boolean,
				required: false,
				default: false,
			},
		},

		setup(props) {
			const layoutProps = useLayout();

			layoutProps.title = props.title;

			watch(props, () => {
				layoutProps.title = props.title;
			});

			onActivated(() => {
				layoutProps.title = props.title;
			});
		},

		render() {
			if (this.$slots.default) {
				return h('div', undefined, this.$slots.default());
			}

			throw new Error('DynamicLayout must have a default slot');
		},
	});

	export default DynamicLayout;
</script>
