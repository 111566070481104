<template>
	<a
		v-if="external"
		class="outline-hidden group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900"
		:href="to"
		:tabindex="isOpened ? undefined : -1"
		:aria-hidden="!isOpened"
	>
		<slot />
	</a>
	<RouterLink
		v-else
		class="outline-hidden group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 focus:bg-gray-50 focus:text-gray-900"
		:to="to"
		:tabindex="isOpened ? undefined : -1"
		:aria-hidden="!isOpened"
	>
		<slot />
	</RouterLink>
</template>

<script name="MenuItem" lang="ts" setup>
	defineProps({
		to: {
			type: String,
			required: true,
		},

		external: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const isOpened = inject('isOpened');
</script>
