<script lang="ts" setup>
	const props = defineProps<{
		src: string;
	}>();

	const image = ref<HTMLImageElement>();
	const state = ref('INIT');

	function loadImage() {
		image.value!.onload = () => {
			if (image.value?.height === 1) {
				state.value = 'IMAGE_NOT_FOUND';
				return;
			}

			state.value = 'IMAGE_LOADED';
		};

		image.value!.onerror = () => {
			state.value = 'IMAGE_NOT_FOUND';
		};

		image.value!.src = props.src;
	}

	onMounted(loadImage);
	watch(() => props.src, loadImage);
</script>

<template>
	<img ref="image" class="hidden" :src="src" alt="" />

	<div v-show="state === 'IMAGE_LOADED'">
		<slot name="loaded" />
	</div>

	<div v-show="state === 'IMAGE_NOT_FOUND'">
		<slot name="notFound" />
	</div>
</template>
