<script lang="ts" setup>
	import IconBeachVolley from '~icons/fivb/beachvolley';
	import IconCaret from '~icons/fivb/carret';
	import IconDevelopment from '~icons/fivb/development';
	import IconGlobe from '~icons/fivb/globe';
	import IconLegal from '~icons/fivb/hammer-3';
	import IconMedia from '~icons/fivb/media';
	import IconMedical from '~icons/fivb/medical';
	import IconMisc from '~icons/fivb/misc';
	import IconTools from '~icons/fivb/tools';
	import IconVolleyball from '~icons/fivb/volleyball';
	import type { FunctionalComponent } from 'vue';

	const props = defineProps({
		name: {
			type: String,
			required: true,
		},

		icon: {
			type: String,
			required: true,
		},

		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const isSmall = inject('isSmall');

	const isOpened = ref(false);

	let icon: FunctionalComponent;

	switch (props.icon) {
		case 'globe': {
			icon = IconGlobe;
			break;
		}
		case 'volleyball': {
			icon = IconVolleyball;
			break;
		}
		case 'beachvolley': {
			icon = IconBeachVolley;
			break;
		}
		case 'medical': {
			icon = IconMedical;
			break;
		}
		case 'development': {
			icon = IconDevelopment;
			break;
		}
		case 'media': {
			icon = IconMedia;
			break;
		}
		case 'tools': {
			icon = IconTools;
			break;
		}
		case 'legal': {
			icon = IconLegal;
			break;
		}
		default: {
			icon = IconMisc;
		}
	}

	provide('isOpened', isOpened);

	function toggle() {
		if (props.disabled || isSmall.value) {
			return;
		}

		isOpened.value = !isOpened.value;
	}
</script>

<template>
	<div class="space-y-1">
		<button
			class="outline-hidden group flex w-full items-center rounded-md focus:ring-0"
			:class="{
				'py-2 pl-2 pr-1': !isSmall,
				'pointer-events-none justify-center text-gray-400': isSmall,
				'text-gray-600 hover:bg-blue-50 hover:text-blue-900 focus:bg-blue-50 focus:text-blue-900': !disabled,
				'cursor-not-allowed text-gray-400': disabled,
				'bg-blue-50 text-blue-900': isOpened && !isSmall,
			}"
			@click="toggle"
		>
			<Component
				:is="icon"
				class="size-6"
				:class="{
					'group-hover:text-blue-500': !disabled,
					'mr-4 text-gray-400': !isSmall,
					'm-0 text-gray-500': isSmall,
				}"
			/>

			<template v-if="!isSmall">
				<span>{{ name }}</span>

				<IconCaret class="ml-auto h-2 fill-current transition-transform" :class="{ 'rotate-90': isOpened }" />
			</template>
		</button>

		<div
			v-if="!isSmall"
			class="grid grid-rows-[0fr] transition-all"
			:class="{ 'grid-rows-[1fr]': isOpened }"
			:aria-hidden="!isOpened"
		>
			<div class="space-y-1 overflow-hidden">
				<slot />
			</div>
		</div>
	</div>
</template>
