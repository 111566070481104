<script lang="ts" setup>
	import Navigation from '~/ui/Navigation/Navigation.vue';

	const isSmall = ref(false);
	provide('isSmall', isSmall);
</script>

<template>
	<div class="flex">
		<div
			class="group/item relative -mr-[5px] flex flex-grow flex-col overflow-y-auto bg-white transition-all"
			:class="{
				'w-12 cursor-pointer px-2': isSmall,
				'w-68 px-2 pb-4': !isSmall,
			}"
			:style="{ scrollbarGutter: isSmall ? 'auto' : 'stable both-edges' }"
			@click="isSmall ? (isSmall = false) : null"
		>
			<div v-if="!isSmall" class="mb-4 mt-10 flex shrink-0 items-center">
				<RouterLink class="focus:outline-hidden group p-1" to="/">
					<svg class="w-32 fill-current text-blue-500" viewBox="0 0 400 144" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M140.743 0H163.789V88.8885H140.743V0ZM66.6693 88.8899H89.7141V52.6781H125.927V32.9225H66.6693V88.8899ZM66.6693 0.00141302V19.7569H132.512V0.00141302H66.6693ZM324.527 43.29C327.224 41.3039 329.424 38.7186 330.953 35.7379C332.481 32.7571 333.297 29.4621 333.335 26.1125C333.335 9.76204 322.611 0.00705704 304.656 0.00705704H231.842L214.94 56.3356H214.68L197.778 0.00705704H172.018V19.7626H180.255L203.324 88.8984H226.308L249.377 19.7626H302.152C309.239 19.7626 310.296 24.4037 310.296 27.1681C310.296 29.9324 309.239 34.5764 302.152 34.5764H262.552V54.3319H303.156C310.241 54.3319 311.298 58.9744 311.298 61.7374C311.298 64.5003 310.241 69.1471 303.156 69.1471H262.558V88.9026H306.496C323.546 88.9026 334.561 78.4604 334.561 62.2976C334.556 50.7209 328.399 45.7933 324.527 43.29V43.29ZM200.004 108.641C128.335 108.641 60.4928 120.575 0 141.854V143.336C65.5208 127.751 132.655 119.971 200.004 120.159C267.349 119.97 334.481 127.747 400 143.328V141.845C339.51 120.582 271.671 108.641 200.004 108.641V108.641Z"
						/>
					</svg>
				</RouterLink>
			</div>

			<div class="flex flex-grow flex-col" :class="{ 'mt-3': isSmall }">
				<Navigation />
			</div>
		</div>

		<button
			class="z-30 w-[5px] bg-white"
			:class="{
				'cursor-w-resize': !isSmall,
				'cursor-e-resize': isSmall,
			}"
			@click.stop="isSmall = !isSmall"
		></button>
	</div>
</template>
