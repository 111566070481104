export function assert(value: unknown, message?: string): asserts value {
	if (!value) {
		throw new Error(message ?? 'value is falsy');
	}
}

export function assertUnreachable(x: never): never {
	throw new Error(`unreachable code executed: ${String(x)}`);
}

export function assertNotNull<T>(
	value: T | null,
	message = 'unexpected null value'
): asserts value is Exclude<T, null> {
	if (value === null) {
		throw new Error(message);
	}
}
